import React from "react"
import { graphql, StaticQuery } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import BlogPostCard from "../../components/blogPostCard"
import TagMenu from "../../components/tagMenu"

import "../../utils/normalize.css"
import "../../utils/css/screen.css"

const Index = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges
  let postCounter = 0

  return (
    <Layout title={siteTitle}>
      <SEO title="Leia Chang" keywords={[`blog`, `gatsby`, `javascript`, `react`, `ITP`]} />
      {data.site.siteMetadata.description && (
        <header className="page-head">
          <h2 className="page-head-title">Writing</h2>
          <TagMenu />
        </header>
      )}

      <div className="blog-post-feed">
        {posts.map(({ node }) => {
          postCounter++
          return (
            <BlogPostCard
              key={node.fields.slug}
              count={postCounter}
              node={node}
              postClass={`post`}
            />
          )
        })}
      </div>
    </Layout>
  )
}

const indexQuery = graphql`{
  site {
    siteMetadata {
      title
      description
    }
  }
  allMarkdownRemark(
    sort: {fields: [frontmatter___date], order: DESC}
    filter: {frontmatter: {tags: {in: ["blog"]}}}
  ) {
    edges {
      node {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          thumbnail {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
}
`

const BlogIndex = props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <Index location={props.location} props data={data} {...props} />
    )}
  />
)

export default BlogIndex
